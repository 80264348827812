@import "../../../../styles/global";
.sb-create-new-issue-dialog{
    width: 600px;
    max-width: 80%;
}
.sb-create-new-issue-dialog-content{
    min-height:300px;
    position: relative;
    .sb-saving{
        font-size: @font-size-normal;
        position: absolute;
        top:8px;
        right: 16px;
    }
}

.sb-create-new-issue-dialog-content__text {
    width: 100%;
}

.sb-create-new-issue-dialog-content__team-select {

    .sb-team-select {
        width: 100%;
    }

}
